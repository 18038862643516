<template>
  <v-app>
    <div class="add-button-container">
      <v-btn
          color="success"
          ref="addButton"
          @click="dialog = true"
      >Thêm bài học
      </v-btn
      >
    </div>
    <div class="group-question-wrapper">
      <div class="add-btn-wrapper">
        <h3>Danh sách bài luyện tập</h3>
        <div class="add-btn">
          <v-btn color="primary" @click="viewGroupQuestion()"
          >Tạo bài tập mới
          </v-btn>
        </div>
      </div>
      <v-alert v-if="getListGroupByExercise.length === 0" :value="true" color="warning" icon="priority_high" outlined>
        Chưa có dữ liệu hiển thị
      </v-alert>
      <v-data-table :headers="headers"
                    :items="getListGroupByExercise"
                    item-key="title"
                    class="elevation-1 custom-size-img"
      >
        <template v-slot:item.title="{ item }">
          <div v-html="item.title.length>250?(item.title.substr(0,250)+'...'):item.title"></div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="viewGroupQuestion(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              @click="deleteGroupQuestion(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:item.list="{ item }">
          <v-btn
              small
              class="mr-2"
              @click="viewListQuestion(item)"
          >
            Xem danh sách câu hỏi
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Thêm bài học</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field
                outlined
                v-model="part"
                append-icon="mdi-magnify"
                ref="search"
                @keydown.enter.prevent="searchKeyword"
                placeholder="Search part..."
                @click:append="searchKeyword"
            ></v-text-field>
            <v-autocomplete
                v-model="selectedGroup"
                :items="exerciseOption"
                chips
                multiple
                outlined
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span> <div class="mt-4" v-html='item.text' /></span>
                </v-chip>
                <span
                    v-if="index===1"
                    class="text-caption black--text"
                >
                  (+{{ countIndex()-1 }} others)
                </span>
              </template>
              <template slot="item" slot-scope="{item}"> <div v-html='item.text' /> </template>
            </v-autocomplete>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn width="50" style="margin-left: 150px; position: absolute;margin-bottom: 50px; margin-top: 20px" color="blue darken-1" text @click="dialog = false">Hủy</v-btn>
          <v-btn
              style="margin-left: 230px; position: absolute;margin-bottom: 50px;margin-top: 20px"
              width="50"
              type="submit"
              @click="selectGroupBYExerciseId"
              color="blue darken-1"
              text
          > Lưu
          </v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <draggable @change="submitOrder" v-model="lessons" handle=".handle">
      <curriculumItem class="curriculum-parent item" v-for="(lesson,lessonIndex) in lessons" :key="lessonIndex"
                      :lesson="lesson"
                      :course_slug="course_slug"
                      @handleAction="handleAction"
      >
        <draggable  @change="submitOrder" handle=".handle" v-model="lesson.children">
          <template v-if="lesson.open">
            <curriculumItem v-for="(child,childIndex) in lesson.children" :key="childIndex"
                            :lesson="child"
                            :course_slug="course_slug"
                            @handleChildAction="handleChildAction"
                            @editTitle="editTitle"
            ></curriculumItem>
          </template>
        </draggable>
      </curriculumItem>
    </draggable>
  </v-app>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import curriculumItem from "@/components/lesson/LessonMenuItem";
import draggable from 'vuedraggable'

import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {
  GET_LIST_LESSON,
  SAVE_LESSON,
  DELETE_LESSON,
  UPDATE_LESSON_TITLE,
  UPDATE_LESSON_ORDER
} from "@/store/lessons.module";
import {SUCCESS} from '@/store/alerts.module';
import {
  GET_DETAIL_EXERCISE,
  GET_LIST_ALL_GROUP_QUESTION,
  SAVE_GROUP_QUESTION,
  LIST_GROUP_BY_EXERCISE,
  DELETE_GROUP_QUESTION_EX,
} from "@/store/exercise-toeic.module";

export default {
  props: ["slug"],
  components: {
    curriculumItem,
    draggable,
  },
  async mounted() {
    await this.$store.dispatch(SET_BREADCRUMB, [{title: "Giáo trình"}]);
    await this.fetchGroupQuestion();
    await this.$store.dispatch(GET_DETAIL_EXERCISE, {
      slug: this.$route.query.slug,
    });
  },
  async created(){
    await this.fetchGroupQuestion();
    await this.$store.dispatch(GET_LIST_ALL_GROUP_QUESTION, {});
    await this.$store.dispatch(GET_DETAIL_EXERCISE, {
        slug: this.$route.query.slug,
      });
  },
  data: () => ({
        part: null,
        selectedGroup: [],
        componentKey: 0,
        addParentBox: {
          title: "",
          isActive: false,
        },
        childTitle: "",
        parentTitle: "",
        addChildBox: {
          title: "",
          isActive: false,
          parentId: 0,
        },
        openLessonIndex: -1,
        disabled: false,
        dialog: false,
        lesson: {
          title: "",
          reference_id: null,
          course_id: null,
        },
        openId: null,
        headers: [
          {text: "Tên nhóm", value: "title"},
          {text: "Số lượng câu hỏi", value: "questions_count"},
          {text: "Ngày tạo", value: "created_date"},
          {text: "Thao tác ", value: "actions"},
          {text: "", value: "list"},
        ],
      }
  ),
  computed: {
    ...mapState({
          course: (state) => state.courses.detailCourse,
        }),
    ...mapGetters({
          getListGroupByExercise: "getListGroupByExercise",
          exercise: "getDetailExercise",
          getAllGroupQuestion: "getAllGroupQuestion"
        }),
    lessonsOption() {
      let arr = [];
      this.lessons.forEach(function (element) {
        let item = {
          text: element.title,
          value: element.id
        }
        arr.push(item);
      });
      return arr;
    },
    lessons: {
      get() {
        return this.$store.state.lessons.lessons
      }
      ,
      set(value) {
        this.$store.commit('setListLesson', value)
      }
    },
    exerciseOption() {
      let arr = [];
      this.getAllGroupQuestion.forEach(function (element) {
        let item = {
          text: element.title,
          value: element.id
        }
         arr.push(item)
      })
      return arr;
    },
  },
  watch: {
    lessons: {
      immediate: true,
      handler() {
        let openId = this.openId;
        this.lessons.forEach(function (part, index, theArray) {
          if (part.id === openId) {
            theArray[index].open = true;
          }
        })
      }
    },
    getListGroupByExercise: {
      immediate: true,
      handler() {
        const _this = this;
        this.selectedGroup = [];
        this.getListGroupByExercise.forEach(function (select) {
          _this.selectedGroup.push(select.origin_group_id);
        })
      }
    }
  }
  ,
  methods: {
    async searchKeyword() {
      await this.$store.dispatch(GET_LIST_ALL_GROUP_QUESTION, {
        part: this.part
      });
    },
    fetchGroupQuestion() {
       this.$store.dispatch(LIST_GROUP_BY_EXERCISE, {
        slug: this.$route.query.slug,
      });
    },
    viewGroupQuestion(item) {
      let routeData = null;
      if (item) {
        routeData = this.$router.resolve({
          path: "/exercise/group-question-detail",
          query: {
            slug: this.$route.query.slug,
            group_question_id: item.group_question_ex_id
          },
        });
      } else {
        routeData = this.$router.resolve({
          path: "/exercise/group-question-detail",
          query: {slug: this.$route.query.slug,},
        });
      }
      window.open(routeData.href, '_self');
    },
    deleteGroupQuestion(item) {
      console.log(item)
      confirm("Bạn có chắc chắn muốn xóa không?") &&
      this.$store
          .dispatch(DELETE_GROUP_QUESTION_EX,  item.id)
          .then((data) => {
            if (data.status) {
              this.fetchGroupQuestion()
            }
          });
    },
    viewListQuestion(item) {
      let routeData = this.$router.resolve({
        path: "/question/list",
        query: {
          exercise_id: item.exercise_id,
          group_question_id: item.group_question_ex_id,
          role: 'exercise'
        },
      });
      window.open(routeData.href, '_self');
    },
    async selectGroupBYExerciseId() {
      let routeData = null;
      let arr_list_group = [];
      arr_list_group.push(this.selectedGroup)
      let payload = {
        id: this.exercise.id,
        group_id: this.selectedGroup,
      }
      await this.$store.dispatch(SAVE_GROUP_QUESTION, payload)
          .then((data) => {
            if (data.status) {
               this.dialog = false
            }
          });
       routeData = await this.$router.resolve({
        path: "/exercise",
        query: {
          slug: this.$route.query.slug,
          viewType: 2,
        },
      });
      await window.location.reload();
    },
    countIndex(){
      return this.selectedGroup.length
    },
    submitOrder() {
      for (let i = 0; i < this.lessons.length; i++) {
        this.lessons[i].order = i;
        let children = this.lessons[i].children;
        if (!children) {
          continue;
        }
        for (let j = 0; j < children.length; j++) {
          children[j].order = j;
        }
      }
      this.$store.dispatch(UPDATE_LESSON_ORDER, {
        lessons: this.lessons
      }).then((data) => {
        if (data.status) {
          this.$store.dispatch(SUCCESS, {
            type: "success_vote",
            message: "Cập nhật vị trí thành công"
          }, {
            root: true,
          });
        }
      });
    }
    ,
    editTitle(lesson) {
      this.$store
          .dispatch(UPDATE_LESSON_TITLE, {
            lesson: lesson,
          })
    }
    ,
    saveLesson() {
      let lesson = {
        title: this.lesson.title,
        reference_id: this.lesson.reference_id,
        seo_title: this.lesson.seo_title,
        seo_description: this.lesson.seo_description,
        course_id: this.course.id,
      };
      this.$store
          .dispatch(SAVE_LESSON, {
            lesson: lesson,
          }).then((data) => {
        if (data.status) {
          this.$store.dispatch(GET_LIST_LESSON, {
            course_slug: this.course_slug,
            lesson: this.lessons[this.openLessonIndex],
          });
          this.openId = data.data.reference_id;
          this.resetDialog();
        }
      });
    }
    ,
    resetDialog() {
      this.lesson.reference_id = null;
      this.lesson.title = "";
      this.lesson.seo_description = "";
      this.lesson.seo_title = "";
      this.dialog = false;
    }
    ,
    redirectPage(lesson_type, lesson_slug, lesson_id) {
      if (lesson_type === 0) {
        this.$router.push({
          path: "/lesson/select",
          query: {lesson_slug: lesson_slug, course_slug: this.course.slug},
        });
      }
      if (lesson_type === 3) {
        this.$router.push({
          path: "/lesson/video",
          query: {lesson_slug: lesson_slug, course_slug: this.course.slug},
        });
      }
      if (lesson_type === 2) {
        this.$router.push({
          path: "/lesson/exam",
          query: {lesson_slug: lesson_slug, course_id: this.course.id},
        });
      }
      if (lesson_type === 1) {
        this.switchExercisePage(lesson_slug, lesson_id);
      }
    }
    ,
    async switchExercisePage(lesson_slug, lesson_id) {
      await this.$store.dispatch(GET_EXERCISE_DETAIL, {
        lesson_slug: lesson_slug,
      });
      if (this.exercise) {
        await this.$router.push({
          path: "/exercise/detail",
          query: {lesson_slug: lesson_slug, course_id: this.course.id},
        });
      } else {
        await this.$router.push({
          path: "/exercise/select",
          query: {
            lesson_slug: lesson_slug,
            lesson_id: lesson_id,
            course_id: this.course.id,
          },
        });
      }
    }
    ,
    handleChildAction(payload) {
      // view lesson detail
      let lesson = payload.item;
      if (payload.action === 1) {
        this.redirectPage(lesson.type, lesson.slug, lesson.id);
      }
      if (payload.action === 5) {
        confirm("Are you sure you want to delete this item?") &&
        this.$store
            .dispatch(DELETE_LESSON, {
              lesson: lesson,
            }).then((data) => {
          if (data.status) {
            let id = lesson.id;
            this.deleteLessonChild(id);
          }
        });
      }
    }
    ,
    deleteLessonChild(id) {
      this.lessons.forEach(function (lesson, index, theArray) {
        if (theArray[index].children.length > 0) {
          theArray[index].children = theArray[index].children.filter(function (child) {
            return child.id !== id;
          });
        }
      });
    }
    ,
    handleAction(payload) {
      let lesson = payload.item;
      if (payload.action === 5) {
        // delete item
        confirm("Are you sure you want to delete this item?") &&
        this.$store
            .dispatch(DELETE_LESSON, {
              lesson: lesson,
            })
            .then((data) => {
              if (data.status) {
                let id = lesson.id;
                this.deleteLessonParent(id)
              }
            });
      }
    }
    ,
    deleteLessonParent(id) {
      this.lessons = this.lessons.filter(function (lesson) {
        return lesson.id !== id;
      });
    }
    ,
    removeItem(item) {
      if (item.reference_id == 0) {
        for (let i = this.lessons.length - 1; i >= 0; i--) {
          if (this.lessons[i].id === item.id) {
            this.lessons[i].splice(i, 1);
            break;
          }
        }
      }
    }
    ,
    // fix thatttttttttttttttttttt
    update(lessonPayload) {
      this.$store
          .dispatch(UPDATE_LESSON_TITLE, {
            lesson: lessonPayload,
          })
          .then(() => {
            this.isEdited = false;
          });
    }
    ,
  }
  ,
}
;
</script>

<style lang="scss">
.v-list-item__icon {
  display: none !important;
}

.add-lesson {
  height: 40px !important;
}

.add-button-container {
  display: flex;
  justify-content: flex-end;

  button {
    width: 150px;
  }
}

.curriculum-parent {
  margin-bottom: 20px;
}
</style>
