var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('div',{staticClass:"add-button-container"},[_c('v-btn',{ref:"addButton",attrs:{"color":"success"},on:{"click":function($event){_vm.dialog = true}}},[_vm._v("Thêm bài học ")])],1),_c('div',{staticClass:"group-question-wrapper"},[_c('div',{staticClass:"add-btn-wrapper"},[_c('h3',[_vm._v("Danh sách bài luyện tập")]),_c('div',{staticClass:"add-btn"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.viewGroupQuestion()}}},[_vm._v("Tạo bài tập mới ")])],1)]),(_vm.getListGroupByExercise.length === 0)?_c('v-alert',{attrs:{"value":true,"color":"warning","icon":"priority_high","outlined":""}},[_vm._v(" Chưa có dữ liệu hiển thị ")]):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 custom-size-img",attrs:{"headers":_vm.headers,"items":_vm.getListGroupByExercise,"item-key":"title"},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.title.length>250?(item.title.substr(0,250)+'...'):item.title)}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.viewGroupQuestion(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteGroupQuestion(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.list",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.viewListQuestion(item)}}},[_vm._v(" Xem danh sách câu hỏi ")])]}}])})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Thêm bài học")])]),_c('v-card-text',[_c('v-container',[_c('v-text-field',{ref:"search",attrs:{"outlined":"","append-icon":"mdi-magnify","placeholder":"Search part..."},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.searchKeyword.apply(null, arguments)},"click:append":_vm.searchKeyword},model:{value:(_vm.part),callback:function ($$v) {_vm.part=$$v},expression:"part"}}),_c('v-autocomplete',{attrs:{"items":_vm.exerciseOption,"chips":"","multiple":"","outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_c('div',{staticClass:"mt-4",domProps:{"innerHTML":_vm._s(item.text)}})])]):_vm._e(),(index===1)?_c('span',{staticClass:"text-caption black--text"},[_vm._v(" (+"+_vm._s(_vm.countIndex()-1)+" others) ")]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.selectedGroup),callback:function ($$v) {_vm.selectedGroup=$$v},expression:"selectedGroup"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticStyle:{"margin-left":"150px","position":"absolute","margin-bottom":"50px","margin-top":"20px"},attrs:{"width":"50","color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Hủy")]),_c('v-btn',{staticStyle:{"margin-left":"230px","position":"absolute","margin-bottom":"50px","margin-top":"20px"},attrs:{"width":"50","type":"submit","color":"blue darken-1","text":""},on:{"click":_vm.selectGroupBYExerciseId}},[_vm._v(" Lưu ")])],1)],1)],1),_c('draggable',{attrs:{"handle":".handle"},on:{"change":_vm.submitOrder},model:{value:(_vm.lessons),callback:function ($$v) {_vm.lessons=$$v},expression:"lessons"}},_vm._l((_vm.lessons),function(lesson,lessonIndex){return _c('curriculumItem',{key:lessonIndex,staticClass:"curriculum-parent item",attrs:{"lesson":lesson,"course_slug":_vm.course_slug},on:{"handleAction":_vm.handleAction}},[_c('draggable',{attrs:{"handle":".handle"},on:{"change":_vm.submitOrder},model:{value:(lesson.children),callback:function ($$v) {_vm.$set(lesson, "children", $$v)},expression:"lesson.children"}},[(lesson.open)?_vm._l((lesson.children),function(child,childIndex){return _c('curriculumItem',{key:childIndex,attrs:{"lesson":child,"course_slug":_vm.course_slug},on:{"handleChildAction":_vm.handleChildAction,"editTitle":_vm.editTitle}})}):_vm._e()],2)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }